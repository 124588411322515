const stepcount = [
  {
    _id: "0",
    image:"/api/webimage/662e12eb051b0274abf4cd20",
    //image: "/images/h0.png",
    description: " Step 1: Search for a suitable doctor",
  },
  {
    _id: "1",
    image:"/api/webimage/662e12ea051b0274abf4cd1c",
   // image: "/images/h0.jpg",
    description: "Step 2: Book an appointment on our website",
  },
  {
    _id: "2",
    image:"/api/webimage/662e0e1813b90687ef7706bf",
  //  image: "/images/h1.jpg",
    description:
      "Step 3: See doctor at your desired time",
  },
];

export default stepcount;
